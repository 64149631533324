import React from "react"
import { graphql } from "gatsby"
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers"

import Container from "../components/container"
import GraphQLErrorList from "../components/graphql-error-list"
import EventsPreviewGrid from "../components/event-preview-grid"
import SEO from "../components/seo"
import Layout from "../containers/layout"
import Header from "../components/header"
import Footer from "../components/footer"

export const query = graphql`
  query IndexPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)index/" }) {
      id
      _id
      title
      introduction
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    events: allSanityEvent(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          headline
          introduction
          publishedAt
          slug {
            current
          }
        }
      }
    }
  }
`
const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const eventNodes = (data || {}).events
    ? mapEdgesToNodes(data.events).filter(filterOutDocsWithoutSlugs)
    : []
  const page = data && data.page

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Header header={page.title} subheader={page.introduction} />
      <Container>
        {eventNodes && (
          <EventsPreviewGrid
            title="Previous matches"
            nodes={eventNodes}
            browseMoreHref="/events/"
          />
        )}
      </Container>
      <Footer />
    </Layout>
  )
}

export default IndexPage
